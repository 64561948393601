import React from 'react';

function LoadingScreen({ isOverlay = true }) {
  // Nav on the user side is fixed, so the next element starts at zero
  // while the dashboard side starts at the height of the navbar.
  // For this reason, we need to know if the user is on the dashbord (/account) side
  // to determine the height of the loading screen.
  const isAccountPage = window.location.pathname.includes('/account');
  const loadingScreen = document.getElementsByClassName('loading-screen');
  const nav = document.getElementsByTagName('nav')[0];
  const [style, setStyle] = React.useState({});

  React.useEffect(() => {
    if (loadingScreen && isAccountPage) {
      const distance = loadingScreen[0].getBoundingClientRect().top;
      setStyle({
        paddingTop: `${distance}px`,
        height: `calc(100vh - ${distance}px)`
      });
    } else if (nav) {
      setStyle({
        paddingTop: `${nav.offsetHeight}px`
      });
    }
  }, [nav, loadingScreen]);

  return (
    <div className={`loading-screen ${isOverlay ? '_overlay' : ''}`} style={style}>
      <div className="loading-screen__content">
        {/* <div className="spinner-grow inline-block w-12 h-12 bg-current rounded-full" role="status">
          <span className="visually-hidden">Loading...</span>
        </div> */}
        <div className="divLoader">
          <svg className="svgLoader" viewBox="0 0 100 100" width="10em" height="10em">
            <path stroke="none" d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50" fill="#984063" transform="rotate(179.719 50 51)"><animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 51;360 50 51" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite" /></path>
          </svg>
        </div>

        {' '}

      </div>
    </div>
  );
}
export default LoadingScreen;
