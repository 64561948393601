/* eslint-disable no-unused-vars */
import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { userActions } from '../../redux/auth/auth.actions';
import Interceptor from '../../Services/Interceptor';
import { alertActions } from '../../redux/alert/alert.actions';
import { getToken } from '../../Services/helpers';

export default function Settings() {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const token = getToken();

  const handleDeleteAccount = () => {
    Interceptor.delete('/customer/account')
      .then(() => {
        dispatch(userActions.logout());
        window.location.href = '/login';
      });
  };

  const validate = (values) => {
    const errors = {};
    if (!values.new_password) {
      errors.new_password = 'Required';
    }

    if (!values.confirm_password) {
      errors.confirm_password = 'Required';
    }

    if (values.confirm_password !== values.new_password) {
      errors.confirm_password = 'Passwords must match';
      errors.new_password = 'Passwords must match';
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      new_password: '',
      confirm_password: ''
    },

    validate,
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      const formData = {
        token,
        new_password: values?.new_password
      };
      Interceptor.post('/customer/account/reset-password', formData)
        .then((res) => {
          setLoading(false);
          dispatch(alertActions.success('Email sent successfully'));
          resetForm();
        })
        .catch((err) => {
          setLoading(false);
          dispatch(alertActions.error('Couldn`t change password, please try again later'));
        });
    },
  });

  return (
    <div>
      <div className="py-6">
        <div className="flex justify-between">
          <h2 className="text-xl font-semibold text-primary-magenta">ACCOUNT SETTINGS</h2>
          {/* <button type="button" className="text-sm font-semibold text-primary-magenta">Edit details</button> */}
        </div>
        <form onSubmit={formik.handleSubmit} className="mt-10 divide-y divide-solid">
          <div className="grid grid-cols-1 gap-6">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
              <label className="block">
                <span className="text-gray-700 text-sm font-semibold">Password</span>
                <input
                  name="new_password"
                  onChange={formik.handleChange}
                  value={formik.values.new_password}
                  type="password"
                  className="

                  mt-5
                  block
                  w-full
                  rounded-full
                  bg-transparent
                  border-gray-300
                  border
                  focus:border-primary-magenta focus:bg-white focus:ring-0
                  placeholder:text-xs
                "
                  placeholder="New password"
                />
                {formik.errors.new_password ? <small className="text-red-500">{formik.errors.new_password}</small> : null}
                <input
                  onChange={formik.handleChange}
                  value={formik.values.confirm_password}
                  name="confirm_password"
                  type="password"
                  className="
                  mt-5
                  block
                  w-full
                  rounded-full
                  bg-transparent
                  border-gray-300
                  border
                  focus:border-primary-magenta focus:bg-white focus:ring-0
                  placeholder:text-xs
                "
                  placeholder="Confirm password"
                />
                {formik.errors.new_password ? <small className="text-red-500">{formik.errors.new_password}</small> : null}
              </label>

            </div>
          </div>
          <button type="submit" className="inline-block rounded-full px-12 py-3 my-10 bg-primary-magenta text-white font-semibold text-xs leading-tight uppercase shadow-md  active:shadow-lg transition duration-150 ease-in-out">
            Save
          </button>

          <div className="py-8 lg:py-0 ">
            <div className="flex gap-x-6 flex-wrap lg:flex-nowrap  items-center">
              <div className="w-full lg:w-4/6 space-y-2">
                <p className="text-base font-semibold">Delete account</p>
                <p className="font-xs ">By deleting your account, you will lose all your data</p>
              </div>
              <div className="w-full lg:w-2/6">
                <button onClick={() => setIsOpen(true)} type="button" className="inline-block w-full rounded-full px-12 py-3 my-10 bg-transparent text-primary-black border border-primary-black font-semibold text-xs leading-tight uppercase shadow-md  active:shadow-lg transition duration-150 ease-in-out">
                  Delete Account
                </button>
              </div>

            </div>
          </div>
        </form>
      </div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setIsOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-[67%]" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full space-y-8 max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-xl text-center font-semibold leading-6 text-primary-magenta"
                  >
                    DELETE ACCOUNT
                  </Dialog.Title>
                  <div className="mt-2 text-center">
                    <p className="text-sm text-gray-500">
                      Are you sure you want to delete your account?
                    </p>
                  </div>

                  <div className="mt-4 flex gap-6 justify-center align-center">
                    <button
                      type="button"
                      className="inline-flex justify-center uppercase rounded-full border border-primary-black  px-4 py-2 text-sm font-medium  focus:outline-none   "
                      onClick={() => setIsOpen(false)}
                    >
                      cancel
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center  text-sm font-semibold uppercase rounded-full border border-transparent text-white bg-primary-magenta px-4 py-2 focus:outline-none "
                      onClick={() => handleDeleteAccount()}
                    >
                      delete account
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

    </div>
  );
}
