/* eslint-disable import/no-extraneous-dependencies */
import ReactDOM from 'react-dom/client';
import './assets/styles/main.css';
import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';

import { Provider } from 'react-redux';
import App from './App';
import { store } from './Store/store';

export default createBrowserHistory();

const root = ReactDOM.createRoot(document.getElementById('root'));
export const history = createBrowserHistory();

root.render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
