/* eslint-disable react/no-array-index-key */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchAllBrands } from '../../redux/brands/brands.actions';
import { fetchAllCategory, fetchAllSubCategory } from '../../redux/shop/shop.actions';

export default function MegaMenuShop() {
  const dispatch = useDispatch();
  const {
    sub_categories,
    categories
  } = useSelector((state) => state?.shopReducer);
  const { brands } = useSelector((state) => state?.brandsReducer);

  useEffect(() => {
    dispatch(fetchAllCategory());
    dispatch(fetchAllBrands());
    // dispatch(fetchProducts());
    dispatch(fetchAllSubCategory());
  }, []);

  return (
    <div className=" rounded py-4 lg:px-8 lg:py-12 bg-transparent lg:bg-primary-magenta lg:block">
      <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div className="grid grid-cols-2 gap-x-6 gap-y-12 lg:grid-cols-3 lg:gap-x-24">
          <div>
            <p className="text-sm font-semibold lg:text-white ">
              Shop By Category
            </p>
            <ul className="mt-8 space-y-2">
              {categories?.slice(0, 8)?.map((category) => (
                <li key={category?.id}>
                  <Link to={`/search?filterByCategory=${category?.id}`} title="" className="text-sm font-medium lg:text-[#D1D5DB]  transition-all duration-200  hover:text-white">
                    {category?.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          <div>
            <p className="text-sm font-semibold lg:text-white ">
              Shop by Products
            </p>
            <ul className="mt-8 space-y-2">
              {sub_categories?.slice(0, 8)?.map((sub_category, index) => (
                <li key={index + 1}>
                  <Link to={`/search?q=${sub_category?.name}`} title="" className="text-sm font-medium lg:text-[#D1D5DB]  transition-all duration-200  hover:text-white">
                    {sub_category?.name}
                  </Link>
                </li>
              // <a key={sub_category?.name} href={`/search?q=${sub_category?.name}`} title="" className="text-sm lg:text-base font-semibold text-primary-black transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 focus:ring-1 focus:ring-gray-900 focus:ring-offset-2">{sub_category?.name}</a>
              ))}

            </ul>
          </div>

          <div>
            <p className="text-sm font-semibold lg:text-white ">
              Shop by Brands
            </p>
            <ul className="mt-8 space-y-2">
              {brands?.results?.slice(0, 8)?.map((brand) => (
                <li key={brand?.id}>
                  <Link to={`/search?filterByBrand=${brand?.slug}`} title="" className="text-sm font-medium lg:text-[#D1D5DB]  transition-all duration-200  hover:text-white">
                    {brand?.brand_name}
                  </Link>

                </li>
              ))}

            </ul>
          </div>

        </div>
      </div>
    </div>
  );
}
