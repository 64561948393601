/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FaUserCheck } from 'react-icons/fa';
import {
  Collapse,
  initTE,
} from 'tw-elements';
import Logo from '../../assets/images/kladika_logo.png';
import AccountMenu from './AccoutMenu';
import CartMenu from './CartMenu';
import MegaMenuShop from './MegaShop';
import { fetchCart } from '../../redux/shop/shop.actions';
import { userActions } from '../../redux/auth/auth.actions';
import { getToken } from '../../Services/helpers';
import Interceptor from '../../Services/Interceptor';

export default function MainHeader() {
  const dispatch = useDispatch();
  const [navOpen, setNavOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const { cart } = useSelector((state) => state?.shopReducer);
  const token = getToken();
  const handleOpen = () => {
    setNavOpen(!navOpen);
  };

  useEffect(() => {
    initTE({ Collapse });
  });

  useEffect(() => {
    if (token) {
      dispatch(fetchCart());
    }
  }, [dispatch]);

  const { user } = useSelector((state) => state.authReducer);

  useEffect(() => {
    if (token) {
      dispatch(userActions.user());
    }
  }, []);

  const renderContent = () => {
    if (!token) {
      return 'Account';
    }

    if (user.role === 'VENDOR') {
      return null;
    }

    return (
      <div className="inline-flex items-center gap-1">
        <FaUserCheck />
        {' '}
        {user?.first_name}
        {' '}
        {user?.last_name}
        {' '}
      </div>
    );
  };

  const handleSearchSubmit = () => {
    window.location.href = `/search?q=${searchValue}`;
  };

  const handleVendorDelete = () => {
    Interceptor.delete('/vendor/account')
      .then(() => {
        dispatch(userActions.logout());
        window.location.href = '/login';
      });
  };

  return (
    <header className="py-4 bg-[#FAFAFA]">
      <div className="px-3.5 lg:px-0 lg:container mx-auto ">
        <div className="flex items-center justify-between">
          <div className="flex lg:hidden">
            <button onClick={handleOpen} type="button" className="text-gray-900">
              {!navOpen ? (
                <span aria-hidden="true">
                  <svg
                    className="w-7 h-7"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                </span>
              ) : (
                <span aria-hidden="true">
                  <svg
                    className="w-7 h-7"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </span>
              )}
            </button>
          </div>
          <div className="flex-shrink-0">
            <Link to="/" title="" className="flex">
              <img className="h-8 lg:h-10" src={Logo} alt="" />
            </Link>
          </div>

          <nav className=" items-end lg:items-center w-1/2 lg:w-1/4 lg:ml-36 lg:mr-auto lg:space-x-16 flex ">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSearchSubmit();
              }}
              className="relative mt-2 w-full"
            >
              <div className="absolute inset-y-0 left-0 flex items-center  pointer-events-none">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.33333 12.6667C10.2789 12.6667 12.6667 10.2789 12.6667 7.33333C12.6667 4.38781 10.2789 2 7.33333 2C4.38781 2 2 4.38781 2 7.33333C2 10.2789 4.38781 12.6667 7.33333 12.6667Z"
                    stroke="#984063"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13.9996 14.0016L11.0996 11.1016"
                    stroke="#984063"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>

              <input
                type="text"
                name="search"
                id="search"
                placeholder="Search"
                value={searchValue || ''}
                onChange={(e) => setSearchValue(e.target.value)}
                className=" bg-transparent border-b focus:ring-0 border-0 block w-full py-2 pl-7 pr-4 placeholder-primary-magenta border-primary-magenta  focus:ring-primary-magenta focus:border-primary-magenta sm:text-sm caret-primary-magenta focus:outline-none"
              />
              <button hidden type="submit" />
            </form>
            {/* <Link to="/cart" title="cart" className=" block lg:hidden  "> */}
            <div className=" block lg:hidden  ">
              <CartMenu cart={cart} />
            </div>

            {/* </Link> */}

          </nav>

          <nav className="hidden lg:flex lg:items-center lg:justify-end lg:space-x-10">
            <Link
              to="/shop"
              title="shop"
              className="peer py-5 text-base font-normal text-primary-black transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 "
            >
              {' '}
              Shop
              {' '}
            </Link>
            <div
              className="hidden lg:w-3/5 top-20 right-0 z-50 absolute peer-hover:flex hover:flex

         flex-col bg-transparent py-5 drop-shadow-lg rounded-bl-2xl"
            >
              <MegaMenuShop />
            </div>
            <Link
              to="/sell-on-kladika"
              title="sell"
              className="text-base font-normal text-primary-black transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 "
            >
              {' '}
              Sell
              {' '}
            </Link>
            <Link
              to="/wishlist"
              title="wishlist"
              className="text-base font-normal text-primary-black transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 "
            >
              {' '}
              Wishlist
              {' '}
            </Link>
            {user.role !== 'VENDOR' && <CartMenu cart={cart} />}
            {user.role === 'VENDOR' && <button type="button" onClick={() => setIsOpen(true)} className="peer py-5 text-base font-normal text-primary-black transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 ">Delete Account</button>}
            {user.role !== 'VENDOR' && (
              <div>
                <Link
                  to={token ? '/account/details' : '/login'}
                  title="account"
                  className="peer py-5 text-base font-normal text-primary-black transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 "
                >
                  {renderContent()}
                </Link>
              </div>
            )}

            {user.role === 'VENDOR' && <Link to="/logout">Logout</Link>}
            <div
              className="hidden right-4 top-20  py-5 bg-transparent z-40 absolute peer-hover:flex hover:flex

flex-col drop-shadow-lg rounded-bl-2xl"
            >
              <AccountMenu />
            </div>
          </nav>
        </div>
        {/* className="block lg:hidden" */}
        <nav
          className={`${
            !navOpen ? 'hidden scale-out-hor-right' : 'block scale-in-hor-right'
          } vista-white absolute left-0 bg-[#FAFAFA] h-full w-full z-30  lg:hidden overflow-scroll pt-6 `}
        >
          <div className="px-4 ">
            <div id="accordionFlushExample">
              <div className="rounded-none border border-t-0 border-l-0 border-r-0 border-neutral-200 bg-white ">
                <h2 className="mb-0 flex justify-between w-full items-center rounded-none border-0 bg-white py-4 px-5 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none  [&:not([data-te-collapse-collapsed])]:bg-white [&:not([data-te-collapse-collapsed])]:text-primary [&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(229,231,235)] " id="flush-headingOne">
                  <Link
                    to="/shop"
                    title="shop"
                    className="focus:border-0  text-base  hover:bg-gray-100 font-normal text-primary-black transition-all duration-200  "
                  >
                    Shop
                  </Link>
                  <button
                    className="group relative flex w-full items-center rounded-none border-0 bg-white  text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none  [&:not([data-te-collapse-collapsed])]:bg-white [&:not([data-te-collapse-collapsed])]:text-primary "
                    type="button"
                    data-te-collapse-init
                    data-te-collapse-collapsed
                    data-te-target="#flush-collapseOne"
                    aria-expanded="true"
                    aria-controls="flush-collapseOne"
                  >

                    <span className="ml-auto -mr-1 h-5 w-5 shrink-0 rotate-[-180deg] fill-[#336dec] transition-transform duration-200 ease-in-out group-[[data-te-collapse-collapsed]]:mr-0 group-[[data-te-collapse-collapsed]]:rotate-0 group-[[data-te-collapse-collapsed]]:fill-[#212529] motion-reduce:transition-none ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="h-6 w-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                        />
                      </svg>
                    </span>
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  className="!visible hidden  border-0"
                  data-te-collapse-item
                  data-te-collapse-collapsed
                  aria-labelledby="flush-headingOne"
                  data-te-parent="#accordionFlushExample"
                >
                  <div className="py-4 px-5">
                    <MegaMenuShop />
                  </div>
                </div>
              </div>
              <div className="rounded-none border border-l-0 border-r-0 border-t-0 border-neutral-200 bg-white ">
                <Link
                  to="/sell-on-kladika"
                  title="sell"
                  className="mb-0 font-normal text-primary-black transition-all duration-200  "
                  id="flush-headingTwo"
                >
                  <span
                    className="group relative flex w-full items-center rounded-none border-0 bg-white py-4 px-5 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none  [&:not([data-te-collapse-collapsed])]:bg-white [&:not([data-te-collapse-collapsed])]:text-primary [&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(229,231,235)] "
                    data-te-collapse-init
                    data-te-collapse-collapsed
                    data-te-target="#flush-collapseTwo"
                    aria-expanded="false"
                    aria-controls="flush-collapseTwo"
                  >
                    Sell on Kladika
                  </span>
                </Link>
              </div>
              <div className="rounded-none border border-l-0 border-r-0  border-t-0 border-neutral-200 bg-white  ">
                <h2 className="mb-0" id="flush-headingThree">
                  <button
                    className="group relative flex w-full items-center rounded-none border-0 bg-white py-4 px-5 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none   [&:not([data-te-collapse-collapsed])]:bg-white [&:not([data-te-collapse-collapsed])]:text-primary [&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(229,231,235)] "
                    type="button"
                    data-te-collapse-init
                    data-te-collapse-collapsed
                    data-te-target="#flush-collapseThree"
                    aria-expanded="false"
                    aria-controls="flush-collapseThree"
                  >
                    <Link
                      to="/account/details"
                      title="account"
                      className="text-base  hover:bg-gray-100 font-normal text-primary-black transition-all duration-200  "
                    >
                      {' '}
                      Account
                      {' '}
                    </Link>
                    <span className="ml-auto -mr-1 h-5 w-5 shrink-0 rotate-[-180deg] fill-[#336dec] transition-transform duration-200 ease-in-out group-[[data-te-collapse-collapsed]]:mr-0 group-[[data-te-collapse-collapsed]]:rotate-0 group-[[data-te-collapse-collapsed]]:fill-[#212529] motion-reduce:transition-none ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="h-6 w-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                        />
                      </svg>
                    </span>
                  </button>
                </h2>
                <div
                  id="flush-collapseThree"
                  className="!visible hidden"
                  data-te-collapse-item
                  aria-labelledby="flush-headingThree"
                  data-te-parent="#accordionFlushExample"
                >
                  <div className="py-4 px-5">
                    <AccountMenu />
                  </div>
                </div>
              </div>
              <div className="rounded-none border border-l-0 border-r-0 border-t-0 border-neutral-200 bg-white ">
                <Link
                  to="/wishlist"
                  title="wishlist"
                  className="mb-0 font-normal text-primary-black transition-all duration-200  "
                  id="flush-headingFour"
                >
                  <span
                    className="group relative flex w-full items-center rounded-none border-0 bg-white py-4 px-5 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none  [&:not([data-te-collapse-collapsed])]:bg-white [&:not([data-te-collapse-collapsed])]:text-primary [&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(229,231,235)] "
                    data-te-collapse-init
                    data-te-collapse-collapsed
                    data-te-target="#flush-collapseFour"
                    aria-expanded="false"
                    aria-controls="flush-collapseFour"
                  >
                    Wishlist
                  </span>
                </Link>
              </div>
              <div className="rounded-none border border-l-0 border-r-0 border-t-0 border-neutral-200 bg-white ">
                <Link
                  to="/cart"
                  title="cart"
                  className="mb-0 font-normal text-primary-black transition-all duration-200  "
                  id="flush-headingFive"
                >
                  <span
                    className="group relative flex w-full items-center rounded-none border-0 bg-white py-4 px-5 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none  [&:not([data-te-collapse-collapsed])]:bg-white [&:not([data-te-collapse-collapsed])]:text-primary [&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(229,231,235)] "
                    data-te-collapse-init
                    data-te-collapse-collapsed
                    data-te-target="#flush-collapseFive"
                    aria-expanded="false"
                    aria-controls="flush-collapseFive"
                  >
                    Cart
                  </span>
                </Link>
              </div>
              <div className="rounded-none border border-l-0 border-r-0 border-t-0 border-neutral-200 bg-white ">
                <Link
                  to="/about-us"
                  title="About Us"
                  className="mb-0 font-normal text-primary-black transition-all duration-200  "
                  id="flush-headingFive"
                >
                  <span
                    className="group relative flex w-full items-center rounded-none border-0 bg-white py-4 px-5 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none  [&:not([data-te-collapse-collapsed])]:bg-white [&:not([data-te-collapse-collapsed])]:text-primary [&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(229,231,235)] "
                    data-te-collapse-init
                    data-te-collapse-collapsed
                    data-te-target="#flush-collapseFive"
                    aria-expanded="false"
                    aria-controls="flush-collapseFive"
                  >
                    About
                  </span>
                </Link>
              </div>
              <div className="rounded-none border border-l-0 border-r-0 border-t-0 border-neutral-200 bg-white ">
                <Link
                  to="/shipping-delivery"
                  title="shipping and delivery"
                  className="mb-0 font-normal text-primary-black transition-all duration-200  "
                  id="flush-headingFive"
                >
                  <span
                    className="group relative flex w-full items-center rounded-none border-0 bg-white py-4 px-5 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none  [&:not([data-te-collapse-collapsed])]:bg-white [&:not([data-te-collapse-collapsed])]:text-primary [&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(229,231,235)] "
                    data-te-collapse-init
                    data-te-collapse-collapsed
                    data-te-target="#flush-collapseFive"
                    aria-expanded="false"
                    aria-controls="flush-collapseFive"
                  >
                    Shipping and Delivery
                  </span>
                </Link>
              </div>
              <div className="rounded-none border border-l-0 border-r-0 border-t-0 border-neutral-200 bg-white ">
                <Link
                  to="/terms-and-conditions"
                  title="terms and condition"
                  className="mb-0 font-normal text-primary-black transition-all duration-200  "
                  id="flush-headingFive"
                >
                  <span
                    className="group relative flex w-full items-center rounded-none border-0 bg-white py-4 px-5 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none  [&:not([data-te-collapse-collapsed])]:bg-white [&:not([data-te-collapse-collapsed])]:text-primary [&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(229,231,235)] "
                    data-te-collapse-init
                    data-te-collapse-collapsed
                    data-te-target="#flush-collapseFive"
                    aria-expanded="false"
                    aria-controls="flush-collapseFive"
                  >
                    Terms and Conditions
                  </span>
                </Link>
              </div>
              <div className="rounded-none border border-l-0 border-r-0 border-t-0 border-neutral-200 bg-white ">
                <Link
                  to="/subscription-payment"
                  title="subscription payment"
                  className="mb-0 font-normal text-primary-black transition-all duration-200  "
                  id="flush-headingFive"
                >
                  <span
                    className="group relative flex w-full items-center rounded-none border-0 bg-white py-4 px-5 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none  [&:not([data-te-collapse-collapsed])]:bg-white [&:not([data-te-collapse-collapsed])]:text-primary [&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(229,231,235)] "
                    data-te-collapse-init
                    data-te-collapse-collapsed
                    data-te-target="#flush-collapseFive"
                    aria-expanded="false"
                    aria-controls="flush-collapseFive"
                  >
                    Subscription
                  </span>
                </Link>
              </div>

            </div>
            {/* <div className="grid ">
              <Link to="/shop" title="shop" className="focus:border-0  text-base py-4 px-2 hover:bg-gray-100 font-normal text-primary-black transition-all duration-200  "> Shop </Link>
              <MegaMenuShop />
              <Link to="/sell-on-kladika" title="sell" className="text-base py-4 px-2 hover:bg-gray-100 font-normal text-primary-black transition-all duration-200  "> Sell </Link>

              <Link to="/account/details" title="account" className="text-base py-4 px-2 hover:bg-gray-100 font-normal text-primary-black transition-all duration-200  "> Account </Link>
              <AccountMenu />
              <Link to="/wishlist" title="wishlist" className="text-base py-4 px-2 hover:bg-gray-100 font-normal text-primary-black transition-all duration-200  "> Wishlist </Link>

              <Link to="/cart" title="cart" className="text-base py-4 px-2 hover:bg-gray-100 font-normal text-primary-black transition-all duration-200  "> Cart </Link>

            </div> */}
          </div>
        </nav>
      </div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setIsOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-[67%]" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full space-y-8 max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-xl text-center font-semibold leading-6 text-primary-magenta"
                  >
                    DELETE ACCOUNT
                  </Dialog.Title>
                  <div className="mt-2 text-center">
                    <p className="text-sm text-gray-500">
                      Are you sure you want to delete your account?
                    </p>
                  </div>

                  <div className="mt-4 flex gap-6 justify-center align-center">
                    <button
                      type="button"
                      className="inline-flex justify-center uppercase rounded-full border border-primary-black  px-4 py-2 text-sm font-medium  focus:outline-none   "
                      onClick={() => setIsOpen(false)}
                    >
                      cancel
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center  text-sm font-semibold uppercase rounded-full border border-transparent text-white bg-primary-magenta px-4 py-2 focus:outline-none "
                      onClick={() => handleVendorDelete()}
                    >
                      delete account
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </header>
  );
}
