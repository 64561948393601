import { Link } from 'react-router-dom';

import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../Services/helpers';
import { userActions } from '../../redux/auth/auth.actions';
import Interceptor from '../../Services/Interceptor';
import Footer from '../Footer/footer';
import Logo from '../../assets/images/kladika_logo.png';
// import CategoriesHeader from '../Headers/categoriesHeader';
// import MainHeader from '../Headers/mainHeader';

export default function PaymentLayout({ children }) {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useSelector((state) => state.authReducer);
  const token = getToken();
  const handleVendorDelete = () => {
    Interceptor.delete('/vendor/account')
      .then(() => {
        dispatch(userActions.logout());
        window.location.href = '/login';
      });
  };
  useEffect(() => {
    if (token) {
      dispatch(userActions.user());
    }
  }, []);
  return (
    <>
      <div className="lg:z-50 lg:top-0 lg:sticky bg-white py-4">
        <div className="px-3.5 py-4 lg:px-0 lg:container mx-auto flex">
          <div className="flex-shrink-0">
            <Link to="/" title="" className="flex">
              <img className="h-8 lg:h-10" src={Logo} alt="" />
            </Link>
          </div>
          <div className="flex gap-x-5 ml-auto items-center">
            {user.role === 'VENDOR' && <button type="button" onClick={() => setIsOpen(true)} className="peer py-5 text-base font-normal text-primary-black transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 ">Delete Account</button>}
            {user.role === 'VENDOR' && <Link to="/logout">Logout</Link>}
          </div>
        </div>
      </div>
      <main className="relative py-10 bg-gray-100">
        <section className="relative ">
          {children}
        </section>
      </main>
      <Footer />
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setIsOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-[67%]" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full space-y-8 max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-xl text-center font-semibold leading-6 text-primary-magenta"
                  >
                    DELETE ACCOUNT
                  </Dialog.Title>
                  <div className="mt-2 text-center">
                    <p className="text-sm text-gray-500">
                      Are you sure you want to delete your account?
                    </p>
                  </div>

                  <div className="mt-4 flex gap-6 justify-center align-center">
                    <button
                      type="button"
                      className="inline-flex justify-center uppercase rounded-full border border-primary-black  px-4 py-2 text-sm font-medium  focus:outline-none   "
                      onClick={() => setIsOpen(false)}
                    >
                      cancel
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center  text-sm font-semibold uppercase rounded-full border border-transparent text-white bg-primary-magenta px-4 py-2 focus:outline-none "
                      onClick={() => handleVendorDelete()}
                    >
                      delete account
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
