/* eslint-disable no-console */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable camelcase */
// import NotFound from "../../Components/Errors/Notfound";
import {
  requestFetchAllBrands,

  requestFetchBrandDetail,

  requestLikeAModel,
  requestSearchBrand,
} from '../../Services/brand.requests';

import { alertActions } from '../alert/alert.actions';
import { BrandTypes } from './brands.types';

// export const fetchFeaturedBrand = (per_page = 10, page = 1) => async (dispatch) => {
//   dispatch({
//     type: BrandTypes.FETCH_FEATURED_Brand_PROCESS
//   });

//   try {
//     const { data: { data: { Brandes } } } = await requestGetFeaturedBrandes(per_page, page);

//     dispatch({
//       type: BrandTypes.FETCH_FEATURED_Brand_SUCCESS,
//       payload: Brandes
//     });
//   } catch (err) {
//     // eslint-disable-next-line no-console
//     console.log(err.response.data);
//   }
// };

// export const fetchBusinesDetail = (BrandId) => async (dispatch) => {
//   dispatch({
//     type: BrandTypes.FETCH_SINGLE_Brand_DETAIL_PROCESS
//   });

//   try {
//     const { data: { data: { Brand } } } = await requestFetchBrandDetail(BrandId);

//     dispatch({
//       type: BrandTypes.FETCH_SINGLE_Brand_DETAIL_SUCCESS,
//       payload: Brand
//     });
//   } catch (err) {
//     dispatch({
//       type: BrandTypes.FETCH_SINGLE_Brand_DETAIL_FAILED,
//     });
//     dispatch(alertActions.error('Buisness not found'));
//     // eslint-disable-next-line no-console
//     console.log(err.response.data);
//   }
// };

export const fetchAllBrands = (next, prev, current_page) => async (dispatch) => {
  dispatch({
    type: BrandTypes.FETCH_ALL_BRANDS_PROCESS
  });

  try {
    const { data: { data } } = await requestFetchAllBrands(next, prev, current_page);
    dispatch({
      type: BrandTypes.FETCH_ALL_BRANDS_SUCCESS,
      payload: data
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err.response.data);
  }
};

export const searchBrands = (query) => async (dispatch) => {
  dispatch({
    type: BrandTypes.FETCH_ALL_BRANDS_PROCESS
  });
  try {
    const { data } = await requestSearchBrand(query);
    dispatch({
      type: BrandTypes.FETCH_ALL_BRANDS_SUCCESS,
      payload: data
    });
  } catch (err) {
    console.log(err.response.data);
  }
};
export const fetchBrandDetails = (brandId) => async (dispatch) => {
  dispatch({
    type: BrandTypes.FETCH_SINGLE_BRAND_DETAIL_PROCESS
  });

  try {
    const { data } = await requestFetchBrandDetail(brandId);

    dispatch({
      type: BrandTypes.FETCH_SINGLE_BRAND_DETAIL_SUCCESS,
      payload: data?.data
    });
  } catch (err) {
    dispatch({
      type: BrandTypes.FETCH_SINGLE_BRAND_DETAIL_FAILED,
    });
    dispatch(alertActions.error('Brand not found'));
    // eslint-disable-next-line no-console
    console.log(err.response.data);
  }
};

// export const searchBrand = (keyword, category, page_no, filterOptions) => {
//   // eslint-disable-next-line no-console
//   console.log(category);
//   return async (dispatch) => {
//     dispatch({
//       type: BrandTypes.FETCH_ALL_BrandES_PROCESS
//     });

//     try {
//       const { data: { data } } = await requestSearchBrand(keyword, category, page_no, filterOptions);

//       dispatch({
//         type: BrandTypes.FETCH_ALL_BrandES_SUCCESS,
//         payload: data
//       });
//     } catch (err) {
//       // eslint-disable-next-line no-console
//       console.log(err.response.data);
//     }
//   };
// };

export const likeBrand = (BrandId) => async (dispatch) => {
  try {
    const { data: { message } } = await requestLikeAModel({ model: 'Brand', model_id: BrandId });

    // dispatch(fetchBusinesDetail(getState()?.BrandReducer?.Brand?.slug));

    dispatch(alertActions.success(message));
  } catch (err) {
    const { message } = err?.response?.data;

    dispatch(alertActions.error(message));
  }
};
