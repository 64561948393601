import { BrowserRouter } from 'react-router-dom';
// import { BrowserRouter } from 'react-router-dom';
import { renderRoutes, routes } from './routes/routes';
// import { AuthStateCheck } from './Global/AuthStateCheck';
import Notify from './Global/Notify';

function App() {
  return (
    <BrowserRouter>
      <Notify>
        {/* <AuthStateCheck> */}
        { renderRoutes(routes) }
        {/* </AuthStateCheck> */}
      </Notify>
    </BrowserRouter>
  );
}
export default App;
