/* eslint-disable no-plusplus */
/* eslint-disable no-prototype-builtins */

import { toast } from 'react-toastify';

const moment = require('moment');

export const getToken = () => {
  const tokenString = sessionStorage.getItem('token');
  const userToken = JSON.parse(tokenString);
  return userToken;
};

export const saveToken = (userToken) => {
  sessionStorage.setItem('token', JSON.stringify(userToken));
  // setToken(userToken.token);
};

export const deleteToken = () => sessionStorage.removeItem('token');

export const redirect_uri = `${window.location.origin}/reset-password`;

export const getDayName = (day_id) => {
  const days = {
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
    7: 'Sunday'
  };
  return days[day_id];
};

export const formatDate = (date) => {
  if (date) {
    const dateTime = moment(date).format('Do MMM YYYY');
    return dateTime;
  }
  return '';
};

export const reload_page = () => {
  setTimeout(() => {
    window.location.reload();
  }, 2000);
};

export const notify = (x) => {
  toast(x);
};

export const FormatAmount = (amount) => amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

// Used to get the time difference between current time and a given time.
// A unix timestamp or a format of 'yyyy-MM-dd'T'HH:mm:ss' must be passed as the given time.
export const timeAgo = (date) => {
  const time = new Date(date).getTime();
  const now = new Date().getTime();
  const diff = (now - time) / 1000;
  const hour = 3600;
  const day = 86400;
  const week = day * 7;
  const month = day * 30;
  const year = day * 365;
  if (diff < 60) {
    return 'just now';
  } if (diff < hour) {
    return `${Math.floor(diff / 60)} minute(s) ago`;
  } if (diff < day) {
    return `${Math.floor(diff / hour)} hour(s) ago`;
  } if (diff < week) {
    return `${Math.floor(diff / day)} day(s) ago`;
  } if (diff < month) {
    return `${Math.floor(diff / week)} week(s) ago`;
  } if (diff < year) {
    return `${Math.floor(diff / month)} month(s) ago`;
  }
  return `${Math.floor(diff / year)} year(s) ago`;
};

export const slugify = (text) => {
  const newText = text.toString().toLowerCase().replace(/\s+/g, '-').replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '');
  return newText;
};

export const spacesToUnderscores = (text) => {
  const newText = text.toLowerCase().toString().replace(/\s+/g, '_');
  return newText;
};

// Add commas to numbers < 1000, e.g., 1,000,000
export const commafy = (num = '') => {
  const newNum = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return newNum;
};

// Copy text to clipboard
export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text)
    .then()
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.log(err);
    });
};

// Get a parameter from the URL
export const getSearchParam = (parameterName) => {
  let result = null, tmp = [];
  const items = window.location.search.substr(1).split('&');
  for (let index = 0; index < items.length; index++) {
    tmp = items[index].split('=');
    if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
  }
  return result;
};

// Regex to check if a string is a valid email address
export const isValidEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

// Regex to check if a string is a valid URL
export const isValidURL = (url) => {
  const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}[.]{0,1}/;
  return re.test(String(url).toLowerCase());
};

export const formatDateTImeReadable = (date) => {
  const dateTime = moment(date).format('Do MMM YYYY, h:mm A');
  return dateTime;
};

export const formatISODateTime = (date) => {
  const d = new Date(date);
  const month = d.getMonth() + 1;
  const day = d.getDate();
  const year = d.getFullYear();
  let hour = d.getHours();
  let minute = d.getMinutes();
  const ampm = hour >= 12 ? 'pm' : 'am';
  hour %= 12;
  hour = hour || 12; // the hour '0' should be '12'
  minute = minute < 10 ? `0${minute}` : minute;
  const strTime = `${month}/${day}/${year} ${hour}:${minute} ${ampm}`;
  return strTime;
};

// Convert 24-hour time to 12-hour time
export const format24HourTime = (time) => {
  let hour = parseInt(time?.substr(0, 2), 10);
  const minute = time?.substr(3, 2);
  const ampm = hour >= 12 ? 'pm' : 'am';
  hour %= 12;
  hour = hour || 12; // the hour '0' should be '12'
  // minute = minute < 10 ? minute : '0'+minute;
  const strTime = `${hour}:${minute} ${ampm}`;
  return strTime;
};

// Round decimals to a given number of decimal places
export const roundDecimals = (num, decimals) => {
  const newNum = Math.round(num * 10 ** decimals) / 10 ** decimals;
  return newNum;
};

// Convert HTML and entities to plain text
export const htmlToText = (html) => {
  if (!html) return '';
  let text = html.replace(/<(?:.|\s)*?>/g, '');

  // Remove all HTML entities
  text = text.replace(/&nbsp;/g, ' ');
  text = text.replace(/&amp;/g, '&');
  text = text.replace(/&quot;/g, '"');
  text = text.replace(/&lt;/g, '<');
  text = text.replace(/&gt;/g, '>');
  text = text.replace(/&#39;/g, "'");
  text = text.replace(/&#34;/g, '"');
  text = text.replace(/&#8211;/g, '-');
  text = text.replace(/&#8220;/g, '"');
  text = text.replace(/&#8221;/g, '"');
  text = text.replace(/&#8217;/g, "'");
  text = text.replace(/&#8230;/g, '...');
  text = text.replace(/&#8212;/g, '-');

  // Remove all new lines
  text = text.replace(/\n/g, ' ');

  // Remove all multiple spaces
  text = text.replace(/\s+/g, ' ');

  // Remove all leading and trailing spaces
  text = text.trim();

  return text;
};

// Check is a string is all numbers
export const isNumeric = (n) => /^-?[\d.]+(?:e-?\d+)?$/.test(n);

// Encode to base64
export const base64Encode = (data) => {
  data = JSON.stringify(data);
  return btoa(data);
};

// Decode from base64
export const base64Decode = (data) => {
  data = atob(data);
  return JSON.parse(data);
};

// Set cookie
/**
*
* @param {string} name - The name of the cookie
* @param {string} value - The value of the cookie
* @param {int} days - The number of days until the cookie expires
*/
export const setCookie = (name, value, days) => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ''}${expires}; path=/; domain=.${process.env.REACT_APP_DOMAIN_NAME}`;
};

// Get cookie
export const getCookie = (name) => {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

// Delete cookie
export const deleteCookie = (name) => {
  setCookie(name, '', -1);
};

// Check if a string is a JSON string
export const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};
