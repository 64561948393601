/* eslint-disable no-return-await */
import _ from 'lodash';
import Interceptor from './Interceptor';

export const requestFetchFeaturedProducts = async () => await Interceptor.get('/collective/products/ranking/new-arrivals?include=properties,media');

export const requestFetchSimilarProducts = async (product_id) => await Interceptor.get(`/collective/products/${product_id}/related?include=properties,media`);

export const requestFetchProductDetail = async (productId) => await Interceptor.get(
    `/collective/products/${productId}?include=properties,media`
);

export const requestFetchAllProducts = async (next, prev, to_price, from_price, brand, size, category, order_by, currentPage = 1, perPage = 5) => {
  if (next === true) {
    currentPage += 1;
  }

  if (prev === true) {
    currentPage -= 1;
  }

  return await Interceptor.get(`/collective/products?${order_by ? `order_by=${order_by}&` : ''}include=properties,media&${to_price ? `filters.price_to=${to_price}&` : ''}${from_price ? `filters.price_from=${from_price}&` : ''}${brand ? `filters.brands=${brand}&` : ''}${size ? `filters.size=${size}&` : ''}${category ? `filters.categories=${category}&` : ''}${currentPage ? `page=${currentPage}&` : ''}${perPage ? `per_page=${perPage}&` : ''} `);
};
export const requestSearchProducts = async (next, prev, keyword, to_price, from_price, brand, size, category, order_by, currentPage = 1, perPage = 5) => {
  if (next === true) {
    currentPage += 1;
  }

  if (prev) {
    currentPage -= 1;
  }

  return await Interceptor.get(`/collective/products?keyword=${keyword}&${order_by ? `order_by=${order_by}&` : ''}include=properties,media&${to_price ? `filters.price_to=${to_price}&` : ''}${from_price ? `filters.price_from=${from_price}&` : ''}${brand ? `filters.brand=${brand}&` : ''}${size ? `filters.size=${size}&` : ''}${category ? `filters.categories=${category}&` : ''}${currentPage ? `page=${currentPage}&` : ''}${perPage ? `per_page=${perPage}&` : ''}`);
};

export const requestFetchAllCategory = async () => await Interceptor.get('/collective/categories');

export const requestFetchSubCategories = async () => await Interceptor.get('/collective/popular-subcategories');

export const requestFetchPopularProducts = async () => await Interceptor.get('/collective/products/ranking/most-popular?include=properties,media');

export const requestLikeAModel = async (id) => await Interceptor.post(`/customer/wishlist/${id}`);

export const requestFetchWishList = async () => await Interceptor.get('/customer/wishlist?include=properties,media');

export const requestAddToCart = async (id, body) => await Interceptor.post('/customer/cart', body);

export const requestFetchCart = async () => await Interceptor.get('/customer/cart?include=properties,media');

export const requestFetchCheckoutQuote = async (body) => await Interceptor.post('/customer/cart/checkout-quotation', body);

export const requestContactUs = async (contactDetail) => await Interceptor.post('/generic/contact-us', contactDetail);

export const requestFetchListingCategory = async (listingCategoryId) => await Interceptor.get(`/generic/listing-categories/${listingCategoryId}?include=properties`);

export const requestFetchListingSubCategory = async (listingCategoryId) => await Interceptor.get(`/generic/listing-categories/${listingCategoryId}/sub-categories`);

export const requestSearchListing = async (keyword, category, listingCategory, pageNo = 1, filterOptions = []) => {
  let propertyQuery = '';
  let location = '';

  filterOptions = _.uniqBy(filterOptions.reverse(), 'name');

  for (let i = 0; i < filterOptions.length; i += 1) {
    if (filterOptions[i]?.name !== 'location') {
      propertyQuery += `&properties[${filterOptions[i]?.name}]=${filterOptions[i]?.value}`;
    } else {
      location = filterOptions[i]?.value;
      break;
    }
  }

  listingCategory = listingCategory.replace('-', ' ');

  return await Interceptor.get(`/generic/search?type=listing&${category ? `sub_category=${category}&` : ''}${location ? `location=${location}&` : ''}include=primaryLocation,primaryContact,properties&${keyword ? `keyword=${keyword}&` : ''}page=${pageNo}&category=${listingCategory}${propertyQuery}`);
};

export const requestCreateReview = async (body) => await Interceptor.post('user/reviews', body);

export const requestContactSeller = async (body) => await Interceptor.post('/generic/contact-us', body);

export const requestFetchListingThatBelongsToABusiness = async (businessId) => await Interceptor.get(`/generic/listings?perPage=100&include=listingCategory,properties,owner,locations&filter[owner]=Business,${businessId}`);
