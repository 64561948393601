// import _ from 'lodash';
import Interceptor from './Interceptor';

export const requestFetchAllBrands = async (next, prev, currentPage = 1) => {
  if (next === true) {
    currentPage += 1;
  }

  if (prev) {
    currentPage -= 1;
  }
  return await Interceptor.get(`/collective/brands?${currentPage ? `page=${currentPage}` : ''}`);
};

export const requestSearchBrand = async (query) => await Interceptor.get(`/collective/brands/search?q=${query}`);
export const requestFetchBrandDetail = async (BrandId) => await Interceptor.get(
    `/collective/brands/${BrandId}`
);

export const requestGetFeaturedBrandes = async (per_page, page_no = 1) => await Interceptor.get(`/generic/Brandes?include=locations,reviews&filter[is_featured]=1&per_page=${per_page}&page=${page_no}`);

export const requestFetchBrandCategories = async () => await Interceptor.get('/generic/categories');

export const requestBrandSubCategories = async (categoryId) => await Interceptor.get(`/generic/categories/${categoryId}/sub-categories`);

export const requestUserBrandes = async (per_page, page_no = 1) => await Interceptor.get(`/user/Brandes?include=category,BrandType,tags,subCategories,owner,locations,likes,recommendations,contacts,hours,gallery,reviews,productAndServices,connections_count,pending_connections_count,creator&page=${page_no}${per_page ? `&per_page=${per_page}` : ''}`);

export const requestBrandTypes = async () => await Interceptor.get('/generic/Brand-types');

export const requestCreateReview = async (body) => await Interceptor.post('user/reviews', body);

export const requestLikeAModel = async (body) => await Interceptor.post('/user/likes', body);

export const requestFetchAUserBrandes = async () => await Interceptor.get('user/Brandes');

export const requestConnectABrand = async (fromBrandId, toBrandId) => await Interceptor.post(`/user/Brandes/${fromBrandId}/connections`, { Brand_id: toBrandId, callbackUrl: `${process.env.REACT_APP_CN_BASE_URL}/account/connections` });

export const requestSuggestEdit = async (body) => await Interceptor.post('/user/edit-suggestions', body);

export const requestClaimBrand = async (BrandId, body) => await Interceptor.post(`/user/Brandes/${BrandId}/claim`, body);
