/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-use-before-define */
import { AUTH_TYPES } from './auth.types';
import { authRequests } from '../../Services/auth.requests';
// import { base64Encode, setCookie } from '../../utils/utils';
import { alertActions } from '../alert/alert.actions';
import { saveToken } from '../../Services/helpers';

export const userActions = {
  register,
  login,
  logout,
  user,
  forgotPassword,
  // facebookLogin,
  // googleLogin
};

const params = (new URL(window.location)).searchParams;

function login(data, from) {
  // First check if the pathname of 'from' is '/' and change it to '/account'
  // to redirect to dashboard after login
  if (from) {
    const url = new URL(from);
    if (url.pathname === '/') {
      url.pathname = '/account/details';
      from = url.toString();
    }
  }

  const process = () => ({
    type: AUTH_TYPES.LOGIN_PROCESS,
    payload: {}
  });
  const success = (payload) => ({
    type: AUTH_TYPES.LOGIN_SUCCESS,
    payload
  });
  const failure = (errors) => ({
    type: AUTH_TYPES.LOGIN_FAILURE,
    payload: errors
  });

  const redirect = params.get('redirect');

  return async (dispatch) => {
    dispatch(process());
    let res;
    if (redirect && redirect.includes('subscription-payment')) {
      res = await authRequests.requestVendorLogin(data.email, data.password);
    } else {
      res = await authRequests.requestLogin(data.email, data.password);
    }
    if (res.success && res.data.data.token) {
      dispatch(success(res.data.data.user));
      saveToken(res.data.data.token);
      sessionStorage.setItem('expiry', res.data.data.expiry);
      if (redirect) {
        window.location.href = redirect;
      } else {
        window.location.href = '/account/details';
      }
    } else {
      dispatch(failure(res.error));
      dispatch(alertActions.error(res.error));
    }
  };
}

function user() {
  // First check if the pathname of 'from' is '/' and change it to '/account'
  // to redirect to dashboard after login

  const process = () => ({
    type: AUTH_TYPES.GET_USER_PROCESS,
    payload: {}
  });
  const success = (payload) => ({
    type: AUTH_TYPES.GET_USER_SUCCESS,
    payload
  });
  const failure = (errors) => ({
    type: AUTH_TYPES.GET_USER_FAILURE,
    payload: errors
  });

  return async (dispatch) => {
    dispatch(process());

    const res = await authRequests.getUser();
    if (res.success) {
      dispatch(success(res.data.data));
    } else {
      dispatch(failure(res.error));
      dispatch(alertActions.error(res.error.message));
    }
  };
}

function logout() {
  authRequests.requestLogout();
  return (dispatch) => {
    dispatch({
      type: AUTH_TYPES.LOGOUT_SUCCESS,
      payload: {}
    });
  };
}

function register(data, from) {
  const process = () => ({
    type: AUTH_TYPES.LOGIN_PROCESS,
    payload: {}
  });
  const success = (payload) => ({
    type: AUTH_TYPES.LOGIN_SUCCESS,
    payload
  });
  const failure = (errors) => ({
    type: AUTH_TYPES.LOGIN_FAILURE,
    payload: errors
  });

  return async (dispatch) => {
    dispatch(process());

    const res = await authRequests.requestRegister(data);
    if (res.success) {
      dispatch(success(res.data.data.user));
      saveToken(res.data.data.token);
      sessionStorage.setItem('expiry', res.data.data.expiry);
      window.location.href = from || '/account/details';
    } else {
      const error = {
        response: res.error,
        message: Object.values(res.error.errors)[0][0]
      };
      dispatch(failure(error));
      dispatch(alertActions.error(error.message));
    }
  };
}

function forgotPassword(data, from) {
  // First check if the pathname of 'from' is '/' and change it to '/account'
  // to redirect to dashboard after login
  if (from) {
    const url = new URL(from);
    if (url.pathname === '/') {
      url.pathname = '/account/details';
      from = url.toString();
    }
  }

  const process = () => ({
    type: AUTH_TYPES.LOGIN_PROCESS,
    payload: {}
  });
  const success = (payload) => ({
    type: AUTH_TYPES.LOGIN_SUCCESS,
    payload
  });
  const failure = (errors) => ({
    type: AUTH_TYPES.LOGIN_FAILURE,
    payload: errors
  });

  const redirect = params.get('redirect');

  return async (dispatch) => {
    dispatch(process());

    const res = await authRequests.requestLogin(data.email, data.password);
    if (res.success && res.data.data.token) {
      dispatch(success(res.data.data.user));
      saveToken(res.data.data.token);
      sessionStorage.setItem('expiry', res.data.data.expiry);
      if (redirect) {
        window.location.href = redirect;
      }
      window.location.href = '/account/details';
    } else {
      dispatch(failure(res.error));
      dispatch(alertActions.error(res.error));
    }
  };
}
