import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../Footer/footer';
import CategoriesHeader from '../Headers/categoriesHeader';
import Logo from '../../assets/images/kladika_logo.png';
import MainHeader from '../Headers/mainHeader';

export default function Layout({ children }) {
  const [forVendor, setForVendor] = useState();
  const currentUrl = encodeURIComponent(window.location.href);

  useEffect(() => {
    if (currentUrl.includes('subscription-payment')) {
      setForVendor(true);
    }
  }, [currentUrl]);
  return (
    <>
      <div className="lg:z-50 lg:top-0 lg:sticky">
        {!forVendor ? <MainHeader /> : (
          <div className="px-3.5 py-4  lg:px-0 lg:container mx-auto">
            <div className="flex-shrink-0">
              <Link to="/" title="" className="flex">
                <img className="h-8 lg:h-10" src={Logo} alt="" />
              </Link>
            </div>
          </div>
        )}
        {!forVendor && <CategoriesHeader />}
      </div>

      <main className="relative">
        <section className="relative  ">
          {children}

        </section>
      </main>
      <Footer />
    </>
  );
}
