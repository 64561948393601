/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchAllSubCategory } from '../../redux/shop/shop.actions';

/* eslint-disable jsx-a11y/anchor-is-valid */
export default function CategoriesHeader() {
  const dispatch = useDispatch();
  const [showMore, setShowMore] = useState(false);
  const {
    sub_categories,
  } = useSelector((state) => state?.shopReducer);

  useEffect(() => {
    dispatch(fetchAllSubCategory());
  }, []);

  const numberOfItems = showMore ? sub_categories.length : 5;

  return (
    <header className="py-4 bg-white">
      <div className="px-3.5 lg:px-0 lg:container mx-auto ">
        <div className="flex items-center justify-between">
          <nav className="flex lg:items-center overflow-auto   w-full  ">
            <ul className="navbar-nav flex space-x-4 flex-nowrap xl:flex-wrap   list-style-none mr-auto">
              <li className="nav-item p-2 shrink-0">
                <Link to="/shop" className="nav-link w-full text-sm lg:text-lg font-semibold text-primary-black transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 focus:ring-1 focus:ring-gray-900 focus:ring-offset-2">
                  {' '}
                  New Arrivals
                  <span className="px-2 py-1 font-normal mx-2 text-[13px] rounded-md text-white  bg-primary-magenta ">#Fresh</span>
                </Link>
              </li>
              <li className="nav-item p-2 shrink-0">
                {' '}
                <Link to="/brands" className="nav-link text-sm  lg:text-base font-semibold text-primary-black transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 focus:ring-1 focus:ring-gray-900 focus:ring-offset-2"> All Brands </Link>
              </li>

              {sub_categories.slice(0, numberOfItems)?.map((sub_category, index) => (
                <li key={index + 1} className="nav-item p-2  shrink-0">
                  <Link to={`/search?q=${sub_category?.name}`} title="" className="text-sm nav-link lg:text-base font-semibold text-primary-black transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 focus:ring-1 focus:ring-gray-900 focus:ring-offset-2">{sub_category?.name}</Link>

                </li>

              ))}
              <li className="nav-item p-2 shrink-0">
                {' '}
                <button type="button" onClick={() => setShowMore(!showMore)} className="nav-link text-xs  lg:text-base font-semibold text-gray-600 transition-all duration-200 rounded focus:outline-none font-pj   ">
                  <span className="px-2 py-1 font-normal mx-2 text-[13px] rounded-3xl text-white  bg-primary-magenta ">{showMore ? 'show less' : 'see more' }</span>
                </button>
              </li>
            </ul>

          </nav>
        </div>
      </div>
    </header>
  );
}
